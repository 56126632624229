import React, { useEffect } from "react";

function MyPdfViewer() {
  const pdfLink = "ROBLEK_Tana_PORTFOLIO.pdf";

  // Function to detect mobile device, defined outside useEffect
  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  useEffect(() => {
    // Automatically download PDF on mobile devices
    if (isMobileDevice()) {
      const link = document.createElement("a");
      link.href = pdfLink;
      link.download = "ROBLEK_Tana_PORTFOLIO.pdf";
      document.body.appendChild(link); // Append to body
      link.click();
      document.body.removeChild(link); // Clean up
    }
  }, []);

  // Render iframe or nothing based on device type
  return (
    <div className="pdf-page">
      {!isMobileDevice() && (
        <iframe title="portfolio" src={pdfLink} allowFullScreen></iframe>
      )}
    </div>
  );
}

export default MyPdfViewer;
