import "./App.css";
import MyPdfViewer from "./components/MyPdfViewer";

function App() {
  return (
    <div className="App">
      <MyPdfViewer></MyPdfViewer>
    </div>
  );
}

export default App;
